import React from 'react';

import './styles.scss';

const Quotes = ({ children }) => (
  <div className="w-11/12 mx-auto my-12">
    <blockquote className="blog-quote">{children}</blockquote>
  </div>
)

export default Quotes;
