import React from 'react';

import './styles.scss';

const ContentImage = ({ src, alt }) => (
  <div className="aspect aspect--16x9 blog-article-image">
    <div className="aspect__inner">
      <img src={src} alt={alt} />
    </div>
  </div>
)

export default ContentImage;

