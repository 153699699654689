import React from 'react';

import './styles.scss';

const UL = ({ children }) => {
    return(
        <ul className="blog-ul">{children}</ul>
    )
}

export default UL;
