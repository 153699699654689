import React, { PureComponent } from 'react';
import { graphql, Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faPinterest, faGooglePlusG, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Img from "gatsby-image";
import RehypeReact from "rehype-react";

import SEO from "../components/seo";
import Container from "../components/container"
import Layout from "../components/layout";
import { HEADER_TYPE, HEADER_THEME } from "../consts";

import '../styles/prism.css';
import './blog-post.scss';

import HeadingContent from "./BlogPost/components/HeadingContent"
import Paragraph from "./BlogPost/components/Paragraph"
import Quotes from "./BlogPost/components/Quotes"
import ContentLink from "./BlogPost/components/ContentLink"
import ContentImage from "./BlogPost/components/ContentImage"
import UL from "./BlogPost/components/UL"


const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: HeadingContent,
    h2: HeadingContent,
    h3: HeadingContent,

    p: Paragraph,
    blockquote: Quotes,
    a: ContentLink,
    img: ContentImage,
    ul: UL,

  }
}).Compiler;

export default class BlogPost extends PureComponent {

  render() {

    const { path, data: { contentfulBlog: { title, author, authorAvatar: {file : {url}},  tags, thumbnail: { fluid }, content: { childMarkdownRemark: { htmlAst }} }} } = this.props;
    return (
      <Layout isExternal headerType={{ type: HEADER_TYPE.BLOG, theme: HEADER_THEME.INVERSE }}>
        <SEO title={title} />
        <Container className="pt-4 md:pt-0">
          <div className="blog-header mb-8 w-10/12 mx-auto">
            <h1 className="blog-title text-center">{title}</h1>

            <div className="flex flex-col md:flex-row items-center justify-center mt-5">
              <div className="flex flex-col md:flex-row items-center mb-2 md:mb-0">
                <div className="bg-cover bg-center h-10 w-10 rounded-full mb-2 md:mb-0 md:mr-6" style={{ backgroundImage: url ? `url(${url})`: "url(https://s3.amazonaws.com/pixpa.com/com/articles/1525891879-683036-peter-sjo-201640-unsplashjpg.png)"}} />

                <span className="blog-info-item">{author}</span>
              </div>

              <span className="hidden md:block blog-info-separator" />

              {/* <span className="blog-info-item mb-2 md:mb-0">Tutorial</span> 

              <span className="hidden md:block blog-info-separator" /> */}

              <span className="blog-info-item mb-2 md:mb-0">{tags.join(', ')}</span>

              <span className="hidden md:block blog-info-separator" />

              <div className="blog-info-item  mb-2 md:mb-0flex items-center pt-2">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faComment} />
                <span>0</span>
              </div>
            </div>
          </div>

          <div className="relative px-6 md:px-0">
            <Img
              className="blog-image"
              fluid={fluid}
              objectFit="cover"
              objectPosition="100% 100%"
              alt={title}
            />

            <div className="flex flex-col justify-center fav-btn">
              <FontAwesomeIcon
                icon={faHeart} />

              <span>12</span>
            </div>
          </div>

          <div className="w-10/12 mx-auto">

            <div className="breadcrumbs flex flex-wrap items-center my-6 md:my-12">
              <Link to="/">Home</Link>
              <span className="breadcrumb-separator">·</span>
              <Link to="/blog">Blog</Link>
              <span className="breadcrumb-separator">·</span>
              <Link className="breadcrumb-current" to={path}>{title}</Link>
            </div>

            <div className="content blog-content">{renderAst(htmlAst)}</div>

            <div className="blog-share-section flex flex-col md:flex-row items-center mb-6">
              <div className="w-full flex flex-row justify-between mb-4 md:mb-0 md:justify-start md:w-auto">
                <a href="#">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faPinterest} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faGooglePlusG} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </div>

              <div className="hidden share-separator md:flex-grow mx-6" />

              <span className="block share-count">0 Shares</span>
            </div>

            <div className="flex items-center">
              {tags.map(tag => <div key={tag} className="blog-tag">{tag}</div>)}
            </div>
          </div>
        </Container>
      </Layout>
    )
  }
}

export const query = graphql`
  query postBySlug($slug: String!) {
      contentfulBlog(slug: { eq: $slug }) {
        author
        authorAvatar {
          file {
            url
          }
        }
        title
        tags
        content {
          childMarkdownRemark {
            htmlAst
            id
          }
        }
        thumbnail {
          fluid(maxHeight: 400) {
            src
            aspectRatio
            srcWebp
            base64
            sizes
            srcSet
            srcSetWebp
          }
        }
      }
    }
`
